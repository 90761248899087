import React from 'react';

import { Video } from './index.styled';

;


type VideoEmbedProps = {
	srcWebm: string
	srcMp4: string
	autoPlay?: boolean
	muted?: boolean
	loop?: boolean
	preload?: 'auto' | 'none' | 'metadata'
};

const VideoEmbed = ({
	srcWebm,
	srcMp4,
	autoPlay = true,
	muted = true,
	loop = true,
	preload = 'auto',
}: VideoEmbedProps) => (
		<Video autoPlay={autoPlay} muted={muted} loop={loop} preload={preload} playsInline={autoPlay}>
			{srcWebm && <source src={srcWebm} type="video/webm" />}
			{srcMp4 && <source src={srcMp4} type="video/mp4" />}
		</Video>
	);

export default VideoEmbed;
