import styled, { css } from 'styled-components';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import colors from '../../../styles/settings/colors';
import { gap, margins } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import { mdBodyLight, xxlTitleRegular } from '../../atoms/Typography/index.styled';

type HeroVideoImageProps = {
    opacity?: string;
    height?: string;
};

const HeroVideoImage__Wrapper = styled.section<HeroVideoImageProps>`
${(props) =>
    props.opacity &&
    props.height &&
    css`

	--navigation-height: 86px;
	position: relative;
	width: 100%;
	height: calc(${props.height}vh - var(--navigation-height));
	overflow: hidden;
	background: black;

	${below(
        breakpoints[768],
        () => css`
            height: calc(100vh - var(--navigation-height));
        `
    )}

	& > picture, video {
		opacity: ${props.opacity}`};
		height: 100%;
	}
`;

const HeroVideoImage__Content = styled(Container)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.monochrome.white};
    text-align: center;
    z-index: 1;
`;
const HeroVideoImage__Heading = styled(xxlTitleRegular)`
    margin-bottom: ${gap[24]};

    ${above(
        breakpoints[768],
        () => css`
            margin-bottom: ${gap[40]};
        `
    )}
`;
const HeroVideoImage__Text = styled(mdBodyLight)`
    ${above(
        breakpoints[960],
        () => css`
            padding-left: ${margins[120]};
            padding-right: ${margins[120]};
        `
    )}

    a {
        color: #ffffff;
    }
`;

export {
    HeroVideoImage__Wrapper,
    HeroVideoImage__Content,
    HeroVideoImage__Heading,
    HeroVideoImage__Text,
};
