import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { above } from '../../../styles/tools/media';

const Video = styled.video`
	object-fit: cover;
    width: 100%;
    height: 100%;

	${above(breakpoints[1440] , () => css`
		width: 100%;
	`)}
`;

export { Video };
