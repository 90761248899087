import React from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {Document} from '@contentful/rich-text-types';
import dynamic from 'next/dynamic';
import CustomImage from '../../../atoms/CustomImage';
import VideoEmbed from '../../../atoms/VideoEmbed';
import {
	SearchHeroVideoImage__Content,
	SearchHeroVideoImage__Heading,
	SearchHeroVideoImage__Left,
	SearchHeroVideoImage__Right,
	SearchHeroVideoImage__Text,
	SearchHeroVideoImage__Wrapper,
} from './index.styled';

type SearchHeroVideoImageProps = {
	image?: {
		id?: string;
		src: string;
		alt: string;
		imageType?: string;
	};
	video?: {
		srcWebm: string;
		srcMp4: string;
	};
	headingText: string;
	bodyText?: Document;
	anchorId?: string;
	opacity?: string;
	height?: string;
};

const DynamicSearchBox = dynamic(() => import('./searchBox'), { ssr: false });



const SearchHeroVideoImage = ({ image, video, headingText, bodyText, opacity, height, anchorId }: SearchHeroVideoImageProps) => (
	<SearchHeroVideoImage__Wrapper id={anchorId || ''} opacity={opacity || '100%'} height={height || '100'}>
		{image && !video && (
			<CustomImage
				id={image.id}
				src={image.src}
				alt={image.alt}
				imageType={image.imageType}
				height="auto"
				width="auto"
			/>
		)}

		{video && (

			<VideoEmbed
				srcWebm={video.srcWebm}
				srcMp4={video.srcMp4}
				autoPlay
				loop
				muted
				preload="auto"
			/>
		)}
		<SearchHeroVideoImage__Content>

			<SearchHeroVideoImage__Left>
			<SearchHeroVideoImage__Heading as="h1">{headingText}</SearchHeroVideoImage__Heading>
			{bodyText && <SearchHeroVideoImage__Text as='div'>
				{documentToReactComponents(bodyText, {
								renderText: text =>
									text.split('\n').flatMap((copy, i) => [i > 0 && <br />, copy]),
							})}
				</SearchHeroVideoImage__Text>}
			</SearchHeroVideoImage__Left>
			<SearchHeroVideoImage__Right>
					<DynamicSearchBox />
			</SearchHeroVideoImage__Right>
		</SearchHeroVideoImage__Content>
	</SearchHeroVideoImage__Wrapper>
);

export default SearchHeroVideoImage;
