import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import CustomImage from '../../atoms/CustomImage';
import VideoEmbed from '../../atoms/VideoEmbed';
import {
	HeroVideoImage__Wrapper,
	HeroVideoImage__Content,
	HeroVideoImage__Heading,
	HeroVideoImage__Text,
} from './index.styled';
import SearchHeroVideoImage from './SearchHeroVideoImage';

type HeroVideoImageProps = {
	image?: {
		id?: string;
		src: string;
		alt: string;
		imageType?: string;
	};
	video?: {
		srcWebm: string;
		srcMp4: string;
	};
	headingText: string;
	bodyText?: Document;
	anchorId?: string;
	opacity?: string;
	height?: string;
	showSearch?: boolean;
};



const HeroVideoImage = ({ image, video, headingText, bodyText, opacity, height, anchorId, showSearch }: HeroVideoImageProps) => (
	showSearch ? <SearchHeroVideoImage headingText={headingText} bodyText={bodyText} image={image} video={video} anchorId={anchorId} opacity={opacity} height={height} /> :
	<HeroVideoImage__Wrapper id={anchorId || ''} opacity={opacity || '100%'} height={height || '100'}>
		{image && !video && (
			<CustomImage
				id={image.id}
				src={image.src}
				alt={image.alt}
				imageType={image.imageType}
				height="auto"
				width="auto"
			/>
		)}

		{video && (

			<VideoEmbed
				srcWebm={video.srcWebm}
				srcMp4={video.srcMp4}
				autoPlay
				loop
				muted
				preload="auto"
			/>
		)}
		<HeroVideoImage__Content>
			<HeroVideoImage__Heading as="h1">{headingText}</HeroVideoImage__Heading>
			{bodyText && <HeroVideoImage__Text as="div">
				{documentToReactComponents(bodyText, {
								renderText: text =>
									text.split('\n').flatMap((copy, i) => [i > 0 && <br />, copy]),
							})}
				</HeroVideoImage__Text>}
		</HeroVideoImage__Content>
	</HeroVideoImage__Wrapper>
);

export default HeroVideoImage;
