import styled, { css } from 'styled-components';
import { Container } from '../../../../styles/generic/container';
import breakpoints from '../../../../styles/settings/breakpoints';
import colors from '../../../../styles/settings/colors';
import { gap, margins } from '../../../../styles/settings/spacing';
import { above, below } from '../../../../styles/tools/media';
import { mdBodyLight, xxlTitleRegular } from '../../../atoms/Typography/index.styled';
import { lineHeight, size, weight } from '../../../../styles/settings/typography';

type SearchHeroVideoImageProps = {
	opacity?: string;
	height?: string;
};

const SearchHeroVideoImage__Wrapper = styled.section<SearchHeroVideoImageProps>`
${props =>
	props.opacity &&
	props.height &&
	css`

	--navigation-height: 86px;
	position: relative;
	width: 100%;
	height: calc(${props.height}vh - var(--navigation-height));
	overflow: hidden;
	background: black;

	${below(
		breakpoints[768],
		() => css`
			height: calc(100vh);
		`
	)}

	& > picture, video {
		opacity: ${props.opacity}`};
		height: 100%;
	}
`;

const SearchHeroVideoImage__Content = styled(Container)`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	color: ${colors.monochrome.white};
	z-index: 1;
	height: 100%;
	padding: ${margins[80]};
	display: flex;
	flex-direction: row;

	gap: ${gap[40]};

	${below(
		breakpoints[768],
		() => css`
			flex-direction: column;
			padding: ${margins[20]};
		`
	)}
`;

const SearchHeroVideoImage__Left = styled(Container)`
	display: flex;
	flex-direction: column;

	justify-content: flex-end;
	padding: 0;

	${above(
		breakpoints[768],
		() => css`
			justify-content: center;
			padding: 0;
		`
	)}

	${below(
		breakpoints[768],
		() => css`
			padding: 0;
			padding-top: ${gap[60]};
			justify-content: center;
			height: auto;
		`
	)}
	align-items: start;
	text-align: left;
	height: 100%;
	width: 100%;
`;
const SearchHeroVideoImage__Right = styled(Container)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	height: 100%;
	width: 100%;

	${above(
		breakpoints[768],
		() => css`
			justify-content: center;
			padding: 0;
		`
	)}

	${below(
		breakpoints[768],
		() => css`
			padding: 0;
			justify-content: start;
		`
	)}
`;

const SearchHeroVideoImage__FormBox = styled(Container)`
	width: 100%;
	height: fit-content;
	background: white;

	${below(
		breakpoints[768],
		() => css`
			padding: ${margins[24]};
		`
	)}

	${above(
		breakpoints[768],
		() => css`
			width: 487px;
			max-width: 487px;
			padding: ${margins[32]};
		`
	)}
`;

const SearchHeroVideoImage__Heading = styled(xxlTitleRegular)`
	margin-bottom: ${gap[24]};
	${below(
		breakpoints[768],
		() => css`
			font-size: ${size[32]};

			font-weight: ${weight[400]};
			line-height: ${lineHeight[40]};
		`
	)}
	${above(
		breakpoints[768],
		() => css`
			margin-bottom: ${gap[40]};
		`
	)}
`;
const SearchHeroVideoImage__Text = styled(mdBodyLight)`
	a {
		color: #ffffff;
	}

	${below(
		breakpoints[768],
		() => css`
			font-size: ${size[16]};
			font-weight: ${weight[300]};
			line-height: ${lineHeight[32]};
		`
	)}
`;

export {
	SearchHeroVideoImage__Wrapper,
	SearchHeroVideoImage__Content,
	SearchHeroVideoImage__Heading,
	SearchHeroVideoImage__Text,
	SearchHeroVideoImage__Left,
	SearchHeroVideoImage__Right,
	SearchHeroVideoImage__FormBox,
};
